// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // api: 'http://test-api.shareme.test/v1/',
  api: 'https://test-api.shareme.co/v1/',
  sizeOfRequests: 270,
  autoCode: "<GENERATED>",
  maxPicturesPerProduct: 8,
  initialNumberOfPriceRanges: 5,
  productKey: "P",
  serviceKey: "S",
  item: "ITEM",
  service: "SERVICE",
  storeRole: "STORE_ADDRESS",
  userRole: "BIZ_ADDRESS",
  userLocationRole: "INVOICE_ADDRESS",
  partnerRole: "REGULAR_ADDRESS",
  returnAddress: "RETURN_ADDRESS_TYPE_CONTACT",
  currencyId: 1151,
  sizeRequestParam: "pageSize",
  productPerPage: 1,
  pageParam: 'pageNumber',
  onboarding: "on_boarding",
  carriers: {
    speedy: "Speedy"
  },
  documentTemplates: [
    "e9375861-6fdc-4f09-a145-9fd41471c464",
    "065f33f2-d8fc-4fb6-8282-9701edcc271d",
    "bc4d4679-b247-4c45-92a7-b8ac2baa112a",
    "5417f51e-aa16-4dab-9f7a-61217a4cc48d"
  ],
  cancelation_reason: 'requested_by_customer',
  hiddenValuesReplacement: '*********',
  hiddenValuesBigReplacement: '************',

  ShareMeReturnTax: 0.11,
  CancellationReturnTax: 0.05,
  lsOrderTab: 'lsot'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
