import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Successes } from 'src/config/labels/successes';
import { ReturnPeriods } from 'src/config/labels/returnPeriods';
import { DocumentEnums } from 'src/config/labels/documentEnums';
import { ValidationHelper } from 'src/infrastructure/validationHelpers/validationHelper';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { ImagesService } from 'src/services/requestServices/images/images.service';
import { PartnersService } from 'src/services/requestServices/partners/partners.service';
import { UsersService } from 'src/services/requestServices/users/users.service';
import { LoggeduserinfoService } from 'src/services/stateServices/loggeduserinfoservice/loggeduserinfo.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { BaseDashboardComponent } from '../base-dashboard/base-dashboard.component';
import { MatTableDataSource } from '@angular/material/table';
import { Contract } from 'src/models/DTO/contracts/contract';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ContractService } from 'src/services/requestServices/contracts/contract.service';
import { Convertor } from 'src/infrastructure/convertorHelpers/convertor';
import { DocumentsService } from 'src/services/requestServices/V2/documents/documents.service';
import { InfoService } from 'src/services/requestServices/V2/info/info.service';
import { LoggedinfoService } from 'src/services/stateServices/V2/loggedinfo/loggedinfo.service';
import { ImageService } from 'src/services/requestServices/V2/image/image.service';
import { DeliveryService } from 'src/services/requestServices/V2/delivery/delivery.service';
import { UpdateDeliveryRequestModel } from 'src/models/requestModels/V2/delivery/UpdateDeliveryRequestModel';
import { MatDialog } from '@angular/material/dialog';
import { PasswordConfirmComponent } from 'src/app/dialogs/confirmPassword/password-confirm/password-confirm.component';
import { ValidationConfig } from 'src/infrastructure/validationHelpers/validationConfig';
import { CompanyTypes } from 'src/config/labels/companyTypes';
import { WorkHours } from 'src/models/DTO/V2/workHours/WorkHours';
import { WorkHours as WorkHoursRequestModel } from 'src/models/requestModels/V2/workHours/WorkHours';
import { StripeService } from 'src/services/requestServices/V2/stripe/stripe.service';
import { StripeOrganisationStatus } from 'src/models/DTO/V2/stripe/StripeOrganisationStatus';
import { STRIPE } from 'src/config/labels/stripe';
import { StripeLinkInfo } from 'src/models/DTO/V2/stripe/StripeLinkInfo';
import { Documents } from 'src/models/DTO/V2/documents/Documents';
import { Document } from 'src/models/DTO/V2/documents/Document';
import { StripeLinkRequestModel } from 'src/models/requestModels/V2/stripe/StripeLinkRequestModel';
import { CarrierService } from 'src/services/requestServices/V2/carrier/carrier.service';
import { Carrier } from 'src/models/DTO/V2/carrier/Carrier';
import { CreateCarrierRequestModel } from 'src/models/requestModels/V2/carrier/CreateCarrierRequestModel';
import { environment } from 'src/environments/environment';
import { CarrierAccount } from 'src/models/DTO/V2/carrier/CarrierAccount';
import { forkJoin, Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedLabels } from 'src/config/labels/sharedLabels';
import { LoadingstateService } from 'src/services/stateServices/loading/loadingstate.service';
import { ContactsService } from 'src/services/requestServices/V2/contacts/contacts.service';
import { PhoneNumberUtil } from 'google-libphonenumber';
declare var bootstrap: any;


@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DeliveryComponent extends BaseDashboardComponent implements OnInit {


  @ViewChild("returns") _modal: ElementRef;

  private _errorMsg: string;
  private _password: string;
  private _carriers: Carrier;
  private _currentCarrier: CarrierAccount = { data: [] };
  private _requirements = [
    {
      req: 'logistics',
      tab: 'return'
    },
    {
      req: 'payments',
      tab: 'return'
    }
  ]

  public delivery : FormGroup;
  public return : FormGroup;
  public stripeStatus: StripeOrganisationStatus;
  public stripeLinkInfo: StripeLinkInfo;
  displayedColumnsForContractsTable: string[];
  contractsTableDataSource: MatTableDataSource<Document>;
  contracts: Documents = { data: [] };
  expandedElement: Document | null;
  public returmAddress: FormGroup;



  constructor(
    protected activatedRoute: ActivatedRoute,
    protected userStateService: UserinfostoreService,
    protected localStorageService: LocalstorageserviceService,
    protected loggeduserinfoservice: LoggeduserinfoService,
    protected router: Router,
    protected imagesService: ImagesService,
    protected sanitizer: DomSanitizer,
    protected usersService: UsersService,
    protected partnersService: PartnersService,
    private FB: FormBuilder,
    private toastr: ToastrService,
    private documentService: DocumentsService,
    protected loggedInfoService: LoggedinfoService,
    protected infoService: InfoService,
    protected imageService: ImageService,
    private deliveryService: DeliveryService,
    private matDialog: MatDialog,
    protected stripeService: StripeService,
    protected carrierService: CarrierService,
    private modalService: NgbModal,
    protected documentsService: DocumentsService,
    private loadingState: LoadingstateService,
    private contactService: ContactsService
  ) {
    super(activatedRoute, userStateService, localStorageService, usersService, loggeduserinfoservice,
      partnersService, imagesService, sanitizer, router, loggedInfoService, infoService, imageService, carrierService, documentsService);;
    this.pageName = "delivery";
    this.loadData();
    this.displayedColumnsForContractsTable = ['number', 'document', 'file', 'date', 'status', 'action'];
    this.loadingState.loading$.subscribe(result => {
      this.isLoading = result;
    });
  }

  ngOnInit(): void {
  }

  check(element) {
    console.log(element);
  }

  async loadData(onDocumentUpdate: boolean = false) {
    let me = this;
    await this.onDirectNavigation();
    await this.getUserInfo();
    const currentOrganization = this.loggedPartner.data.organisations[0];
    this.returmAddress = this.FB.group({
      state: [currentOrganization.return_address?.province, [Validators.required]],
      number: [currentOrganization.return_address?.street_number, [Validators.required]],
      city: [currentOrganization.return_address?.city, [Validators.required]],
      postcode: [currentOrganization.return_address?.post_code, [Validators.required]],
      residence: [currentOrganization.return_address?.district],
      telephone: [currentOrganization.return_address?.mobile_phone_1_e164, [Validators.required, this.validateTell.bind(this)]],
      street: [currentOrganization.return_address?.street_name, [Validators.required]],
      details: [currentOrganization.return_address?.description]
    });
    this.delivery = this.FB.group({
      deliveryToAddressFee: [currentOrganization.delivery_to_address_price, [Validators.required, Validators.pattern(ValidationConfig.regexes.number)]],
      deliveryToCourierOfficeFee: [currentOrganization.delivery_to_courier_office_price, [Validators.required, Validators.pattern(ValidationConfig.regexes.number)]],
      // deliveryViaOwnTransport: [currentOrganization.de, [Validators.required]],
      // deliveryViaOwnTransportFee: [this.loggedUser.partner.deliveryViaOwnTransportFee, [Validators.required]],
      freeShippingOverAmount: [currentOrganization.free_delivery_after_order_amount, [Validators.required, Validators.pattern(ValidationConfig.regexes.number)]],
      speedyUsername: ['', [Validators.required]],
      speedyPassword: ['', [Validators.required]]

    });
    this.return = this.FB.group({
      // freeReturnPeriod: [currentOrganization.free_delivery_after_order_amount, [Validators.required]],
      returnPeriod: [currentOrganization.product_max_return_period_in_days, [Validators.required]],
    });

    if (!currentOrganization.product_max_return_period_in_days) {
        let tabEl = document.querySelector('a[href="#return"]');
        let tab = new bootstrap.Tab(tabEl);
        tab.show();
        this.modalService.open(this._modal, { centered: true } );
    }
    else if (!this.areDocumentsFilled()) {
      let tabEl = document.querySelector('a[href="#documents"]');
      let tab = new bootstrap.Tab(tabEl);
      tab.show();
      setTimeout(() => {
        me.modalService.open(this._modal, { centered: true } );
      }, 1000)
    }
    this._carriers = await this.carrierService.getCarriers().toPromise();
    this.getContratcts(onDocumentUpdate);
  }

  private getCarrierByName(name: string) {
    return this._carriers.data.find(carrier => carrier.name = name);
  }


  private createCarrier() {
    const createCarrierModel: CreateCarrierRequestModel = {
      accountable: {
        uuid: this.loggedPartner.data.organisations[0].uuid,
        type: 'ORGANISATION'
      },
      carrier_uuid: this.getCarrierByName(environment.carriers.speedy)?.uuid,
      username:  this.delivery.get('speedyUsername').value,
      password:  this.delivery.get('speedyPassword').value
    }
    return this.carrierService.createCarrierAccount(createCarrierModel).toPromise();
  }

  get errorMessage(): string {
    return this._errorMsg;
  }

  get returnPeriods() {
    return ReturnPeriods.periods[this._language];
  }

  get sharedLabels() {
    return SharedLabels[this._language]
  }


  private validate(formGroup: FormGroup) {
    let fieldName = "";
    fieldName = ValidationHelper.formGroup.validate(formGroup, this.dashboardLables(this.getPageName()));
    if (fieldName != '') {
      this.error = true;
      this._errorMsg = fieldName + this.dashboardLables(this.getPageName()).invalidField;
      return false;
    }
    return true;
  }

  validateTell (control: AbstractControl): {[key : string]: boolean} | null {
    const value = control.value?.toString();
    const phoneUtils = PhoneNumberUtil.getInstance();
    try {
      const number = phoneUtils.parse(value, "BG");
      if (phoneUtils.isValidNumberForRegion(number, "BG")) {
        return null;
      }
      return {'validateTell': false};
    }
    catch(error) {
      return {'validateTell': false};
    }
  };

  saveReturnAddress(): void {
    const currentOrganisation = this.loggedPartner.data.organisations[0];
    if (currentOrganisation.return_address?.uuid) {
      this.contactService.updateOrganisationContact({
        contactable_type: 'ORGANISATION',
        contactable_uuid: currentOrganisation.uuid,
        country_uuid: 'fee09419-17d4-4ec0-bf7d-a3b12b5a5cc9',
        type: environment.returnAddress,
        city: this.returmAddress.get('city').value,
        county: 'България',
        postcode: this.returmAddress.get('postcode').value,
        street_name: this.returmAddress.get('street').value,
        street_number: this.returmAddress.get('number').value,
        mobile_phone_1_country_code: 'BG',
        mobile_phone_1: this.returmAddress.get('telephone').value,
        contact_person_first_name: 'NO',
        contact_person_last_name: 'NO',
        district: this.returmAddress.get('residence').value,
        description: this.returmAddress.get('details').value,
        province: this.returmAddress.get('state').value,
      }, null, this.loggedPartner.data.organisations[0].return_address?.uuid).subscribe(() => {
        this.loadData();
        this.toastr.success('Успешно въведохте адрес за връщане');
      });

    }
    else {
      this.contactService.createOrganisationContact({
        contactable_type: 'ORGANISATION',
        contactable_uuid: currentOrganisation.uuid,
        country_uuid: 'fee09419-17d4-4ec0-bf7d-a3b12b5a5cc9',
        type: environment.returnAddress,
        city: this.returmAddress.get('city').value,
        county: 'България',
        postcode: this.returmAddress.get('postCode').value,
        street_name: this.returmAddress.get('street').value,
        street_number: this.returmAddress.get('number').value,
        mobile_phone_1_country_code: 'BG',
        mobile_phone_1: this.returmAddress.get('telephone').value,
        contact_person_first_name: 'NO',
        contact_person_last_name: 'NO',
        district: this.returmAddress.get('residence').value,
        description: this.returmAddress.get('details').value,
        province: this.returmAddress.get('state').value,
      }).subscribe(() => {
        this.loadData();
        this.toastr.success('Успешно въведохте адрес за връщане')
      })
    }
  }


  update(formGroup: FormGroup) {
    if (!this.validate(formGroup)) {
      return false;
    }
    this.clearError();
    // const dialogRef  = this.matDialog.open(PasswordConfirmComponent, {
    //   width: '400px',
    //   height: '350px',
    //   disableClose: true,
    //   data: {password: this._password}
    // });
    const currentOrganization = this.loggedPartner.data.organisations[0];
    // dialogRef.afterClosed().subscribe(result => {
    //   this._password = result;
    const updateDeliveryModel: UpdateDeliveryRequestModel = {
      delivery_to_address_price: this.delivery.get('deliveryToAddressFee').value || 0,
      delivery_to_courier_office_price: this.delivery.get('deliveryToCourierOfficeFee').value || 0,
      free_delivery_after_order_amount: this.delivery.get('freeShippingOverAmount').value || 0,
      product_max_return_period_in_days: this.return.get('returnPeriod').value || 14,
      partner_uuid: this.loggedPartner.data.uuid,
      native_name: currentOrganization.native_name,
      name_in_latin: currentOrganization.name_in_latin,
      legal_form: CompanyTypes.compTypes[this._language].find((type) => type.type == currentOrganization.legal_form.label).value,
      number: currentOrganization.number,
      vat_registered: (currentOrganization.vat_number) ? true : false,
      vat_number: currentOrganization.vat_number,
      description: { bg: currentOrganization.description } ,
      bank_name: currentOrganization.bank_name,
      iban: currentOrganization.iban,
      bic_swift_code: 'BUINBGSF',
      opening_hours: this.prepareWorkHours(currentOrganization.opening_hours),
      partner_type: currentOrganization.partner_type
    };
    this.deliveryService.updateDelivery(updateDeliveryModel, currentOrganization.uuid).subscribe(() => {
        this.loadData();
        this.toastr.success(Successes.updatePartner[this._language]);
        let tabEl = document.querySelector('a[href="#documents"]');
        let tab = new bootstrap.Tab(tabEl);
        tab.show();
    });
    // });
  }

  public async toStripe() {
    const currentOrganization = this.loggedPartner.data.organisations[0];
    this.stripeStatus = await this.stripeService.getCurrentStripeStatus(currentOrganization.uuid).toPromise();
    const stripeLinkInfoModel: StripeLinkRequestModel = {
      link_type: STRIPE.statuses[this.stripeStatus.data[0].provider_account_status].forLink,
      organisation_uuid: currentOrganization.uuid,
      provider: this.stripeStatus.data[0].provider
    }
    this.stripeLinkInfo = await this.stripeService.createLink(stripeLinkInfoModel).toPromise();
  }

  get stripeLabels() {
    return STRIPE;
  }

  get areDocumentsInvalid() {
    return this.contracts.data.some(contract => contract.download?.is_accepted == false)
  }


  private prepareWorkHours(workHours: WorkHours) : WorkHoursRequestModel  {
    let response : WorkHoursRequestModel = {} as any;
    Object.keys(workHours).forEach(key => {
      if (Array.isArray(workHours[key]) && workHours[key].length) {
        response[key] = {};
        response[key].hours = workHours[key][0].split('-');
      }
    })
    return response;
  }

  private checkAllDocumentsForValidity() {
    for (let index = 0; index < this.contracts.data.length; index++) {
      if (!this.contracts.data[index]?.download?.is_accepted) {
        return false;
      }
    }
    return true;
  }

  confirmTermsAndConditionsAccepted(element) {
    element.termsAndConditionsAccepted = !element.termsAndConditionsAccepted
  }

  confirmOfficialRepresentative(element) {
    element.officialRepresentativeConfirmed = !element.officialRepresentativeConfirmed
  }

  documentEnumName(key: string) {
    if (!DocumentEnums.names[key]) return '';
    return DocumentEnums.names[key][this._language] || "";
  }

  updateContract(element: Document) {
    if (!this.validateTerms(element)) {
      return false;
    }
    this.clearError();
    this.documentService.acceptDocument(element.download.uuid).subscribe(() => {
      this.successfullContractUpdate();
    })
  }

  successfullContractUpdate() {
    this.loadData(true);
    this.toastr.success(Successes.updateTerms[this._language]);
  }

  validateTerms(element: Document) {
    if (!element || !element.termsAndConditionsAccepted || !element.officialRepresentativeConfirmed){
      element.officialRepresentativeConfirmedIsValid = element.officialRepresentativeConfirmed;
      element.termsAndConditionsAcceptedIsValid = element.termsAndConditionsAccepted;
      this.error = true;
      this._errorMsg = this.dashboardLables(this.getPageName()).termsnotconfirmed;
      return false;
    }
    return true;
  }

  clearError() {
    this.error = false;
    this._errorMsg = '';

  }

  getContratcts(onDocumentUpdate: boolean = false) {
    this.documentService.getDocuments().subscribe((documents) => {
      this.contracts.data = documents.data.filter((document) => environment.documentTemplates.includes(document.uuid));
      if (!this.contracts.data[0]?.download?.uuid) {
        forkJoin(this.documentService.generateDocuments()).subscribe((documents) => {
          this.getContratcts();
        });
      }
      else {
        this.contracts.data = this.contracts.data.map(element => {
          element.termsAndConditionsAccepted = true;
          element.officialRepresentativeConfirmed = true;
          element.termsAndConditionsAcceptedIsValid = true;
          element.officialRepresentativeConfirmedIsValid = true;
          return element;
        });
        this.contractsTableDataSource = new MatTableDataSource(this.contracts.data);
        if (this.checkAllDocumentsForValidity() && onDocumentUpdate) {
          this.router.navigate(['dashboard/logistics']);
        }
        else if (!this.contracts.data[0].download?.is_accepted) {
          this.expandedElement = this.contracts.data[0]
        }
      }
    })
  }

  open(documentUUID) {
    this.documentService.downloadDocument(documentUUID).subscribe((file) => {
      const blob = new Blob([file], { type: 'application/pdf' }); // you can change the type
      const url= window.URL.createObjectURL(blob);
      window.open(url)
    });
  }

  convertDate(date: string) {
    return new Date(date).toLocaleString();
  }
}
